<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE STATE -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.status && Object.values(currentFilters.status).length > 0,
                        changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.status"
                    :items="Object.values(optionsStatus)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterGroups',
    components: { FilterActions },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                name: '',
                status: [{ id: 1, name: 'tools.groups.status.active' }]
            },
            activeFilters: {
                name: '',
                status: [{ id: 1, name: 'tools.groups.status.active' }]
            },

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },
    computed: {
        optionsStatus() {
            return this.$store.getters['groups/getFilterStatus']()
        },
        showClearFiltersButton() {
            return JSON.stringify(this.activeFilters) !== JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.locations
            delete this.currentFilters.territories
        },

        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (this.currentFilters.status) {
                var filter = this.currentFilters.status
                definitiveFilters.status = []
                for (var index in filter) {
                    definitiveFilters.status.push(filter[index].id)
                }
                definitiveFilters.status = definitiveFilters.status.toString()
            } else {
                definitiveFilters.status = ''
            }

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('groups/setFiltersActive', this.currentFilters)
            this.$store
                .dispatch('groups/loadList', {
                    name: definitiveFilters.name,
                    status: definitiveFilters.status
                })
                .then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            for (let name in this.activeFilters.status) {
                let index = JSON.parse(JSON.stringify(this.activeFilters.status[name]))
                if (index.name) {
                    index.name = i18n.t(index.name)
                }
            }
            this.$store.commit('groups/setFiltersActive', this.currentFilters)
            this.changesDetected = false
            this.$store.dispatch('groups/loadList', { status: 1, name: '' }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        let translated = JSON.parse(JSON.stringify(this.$store.getters['groups/getFiltersActive']))
        for (let name in translated.status) {
            let index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.emptyFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
