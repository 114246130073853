<template>
    <div id="content" class="group-management">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveGroup" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showDeleteLocationsGroupModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="groupRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <div class="user-answer">
                            <Input @input="updateValues($event)" v-model="groupRender.name" :gOptions="{ mandatory: true }" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('groups.form.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></Input>
                            <!-- <input type="text" v-model="groupRender.name" class="input w85" autocomplete="off" name="name"  /> -->
                        </div>
                    </div>
                    <div class="right-container">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="groupRender.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="groupRender.status" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('groups.form.switch_inactive')"></p>
                    </div>
                </div>
                <!-- <br /> -->
                <div class="form-title">
                    <h1 class="form-title">{{ $t('groups.form.title_table') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('groups.form.search') }}</h2>

                        <div class="user-answer">
                            <input type="text" v-model="search" class="input w85" autocomplete="off" name="name" />
                        </div>
                    </div>
                    <div class="right-container"></div>
                </div>
                <div class="data-table">
                    <v-data-table v-if="items" :headers="headers" fixed-header :items="Object.values(items)" :search="search" :items-per-page="-1" :hide-default-footer="true" :ref="'groupDataSelected'" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :value="selected" show-select>
                        <template #item.identification="item">
                            <div class="id">
                                <span class>{{ item.item.identification }}</span>
                            </div>
                        </template>
                        <template #item.name="item">
                            <div>
                                <span class>{{ item.item.name }}</span>
                            </div>
                        </template>
                        <!-- <template #item.selected="item"></template> -->
                        <template v-slot:no-data>
                            <div class="no-data">{{ $t('supervise.checklists.table_headers.no_data') }}</div>
                        </template>
                    </v-data-table>
                    <!-- <div id="pagination" v-if="!items.length == 0">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ Object.values(items).length }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'GroupManagement',
    props: { id: undefined },
    components: {},
    data() {
        return {
            idGroup: false,
            moreOptions: false,
            statusGroup: undefined,
            sendForm: true,
            errors: {
                name: false
            },
            sortBy: 'selected',
            sortDesc: true,
            selectedLocations: [],
            form: false,
            values: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20,
                descending: true
            },
            search: '',
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('user.id'),
                    value: 'identification',
                    align: 'start',
                    width: '20%',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '80%',
                    class: 'header-table'
                },
                {
                    text: 'selected',
                    value: 'selected',
                    sortable: true,
                    sort: this.sortBySelected,
                    align: ' d-none'
                }
            ]
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['location/getList']()
        },
        groupRender: {
            get() {
                if (this.idGroup) {
                    var group = this.$store.getters['groups/getList'](this.idGroup)
                    if (group.status == -2) {
                        group.status = 0
                    }
                    this.selectedLocations = []
                    this.selected = []
                    for (var key in group.locations) {
                        this.selectedLocations.push(key)
                    }
                    this.selectedLocations.forEach((location) => {
                        if (this.items[location]) {
                            this.items[location].selected = true
                        }
                        this.selected.push({ ...this.items[location] })
                    })
                    return group
                }
            },
            set(value) {
                this.$store.commit('groups/updateGroup', value)
            }
        }
    },
    methods: {
        updateValues(v) {
            this.groupRender = this.groupRender
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },

        sortBySelected(a, b) {
            // console.log("ega");
            var aSelected = typeof a != 'undefined' && a
            var bSelected = typeof b != 'undefined' && b
            if (aSelected && !bSelected) {
                return 1
            } else {
                return -1
            }
        },

        popupStatus() {
            var self = this
            if (self.groupRender.status == '1' || self.groupRender.status == true) {
                self.$popup.confirm({
                    message: self.$t('groups.form.popup_group_text'),
                    textSave: self.$t('groups.form.popup_group_save'),
                    callSave: function () {
                        self.groupRender.status = 0
                        self.updateValues(self.groupRender.status)
                    },
                    callCancel: function () {
                        self.groupRender.status = 1
                        self.updateValues(self.groupRender.status)
                    }
                })
            } else {
                self.groupRender.status = 1
                self.updateValues(self.groupRender.status)
            }
        },

        load() {
            var self = this
            // Cargar estados cuando se hace refresh en el formulario
            if (Object.keys(self.$store.getters['groups/getList']).length == 0) {
                self.$store.dispatch('groups/loadList', {}).then(() => {
                    if (self.$route.name == 'Groups') {
                        if (typeof self.id != 'undefined') {
                            self.$router.push({
                                name: 'EditGroup',
                                params: { id: self.id }
                            })
                            self.idGroup = self.id
                        } else {
                            self.$store.dispatch('groups/addNewGroup').then((id) => {
                                self.idGroup = id
                                self.$router.push({ name: 'AddGroup' })
                            })
                        }
                    } else {
                        if (typeof self.id != 'undefined') {
                            self.idGroup = self.id
                        } else {
                            self.$store.dispatch('groups/addNewGroup').then((id) => {
                                self.idGroup = id
                            })
                        }
                    }
                })
            } else {
                if (self.$route.name == 'Groups') {
                    if (typeof self.id != 'undefined') {
                        self.$router.push({
                            name: 'EditGroup',
                            params: { id: self.id }
                        })
                    } else {
                        self.$store.dispatch('groups/addNewGroup').then((id) => {
                            self.id = id
                            self.$router.push({ name: 'AddGroup' })
                        })
                    }
                }
            }
        },

        goBack() {
            if (this.selected.length > 0) {
                Object.values(this.items).forEach(function (v) {
                    delete v.selected
                })
            }
            if (!this.$route.params.id) {
                this.$store.commit('library/deleteLibraryCategory', this.idCategory)
            }
            this.$overlay.show()
            this.$emit('back')
        },
        saveGroup() {
            // console.log(this.$refs.groupDataSelected.selection);

            var self = this
            self.$overlay.loading()
            this.$store.dispatch('groups/validateGroup', this.idGroup).then((response) => {
                if (response.status) {
                    this.groupRender.locations = Object.values(this.$refs.groupDataSelected.selection)
                    if (this.groupRender.locations.length > 0) {
                        var group = []
                        for (var i in this.groupRender.locations) {
                            var idx = this.groupRender.locations[i]
                            if (idx.id) {
                                group.push(idx.id)
                            }
                        }
                        this.groupRender.locations = group.join()
                    }
                    var sendForm = self.$route.params.id ? 'editGroup' : 'addGroup'
                    self.$store.dispatch('groups/' + sendForm, self.idGroup).then((response) => {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + self.groupRender.name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + self.groupRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },

        deleteLocationsGroup() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.groupRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('groups/deleteGroup', { id: self.groupRender.id }).then(() => {
                self.$emit('back')
                self.$snackbar.open(snackbarData)
            })
        },

        showDeleteLocationsGroupModal() {
            var self = this
            const modalMessage = self.$t('groups.form.popup_delete') + self.groupRender.name + "'?"

            this.$popup.delete({ message: modalMessage, callSave: this.deleteLocationsGroup })
        }
    },

    created() {
        this.load()
        this.selected = []
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content.group-management {
    .form {
        margin-top: -30px;
    }
    .form-title {
        margin-bottom: 8px;
    }
    .form-container .label-container {
        padding-bottom: 8px;
    }
    .left-container {
        padding-left: 0;
    }

    .data-table {
        box-shadow: none;
    }

    td {
        height: 2.5rem !important;
    }
    tr {
        border-bottom: 1px solid #fff;
    }
}
</style>
