var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box",class:{ 'empty-results': _vm.isDisabled }},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('assets.filter.filter_data')))]),_c('div',{staticClass:"filter-items"},[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('sites.table_headers.name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.name),expression:"currentFilters.name"}],staticClass:"input",class:{
                    filtered: _vm.currentFilters.name && Object.values(_vm.currentFilters.name).length > 0,
                    changed: _vm.currentFilters.name && _vm.currentFilters.name != _vm.activeFilters.name
                },attrs:{"type":"text","hide-details":""},domProps:{"value":(_vm.currentFilters.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilters()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters, "name", $event.target.value)}}})]),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('sites.table_headers.state')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.status && Object.values(_vm.currentFilters.status).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.status) != JSON.stringify(_vm.activeFilters.status)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsStatus),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}]),model:{value:(_vm.currentFilters.status),callback:function ($$v) {_vm.$set(_vm.currentFilters, "status", $$v)},expression:"currentFilters.status"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.status,"nameTag":'name',"value":"id","removable":true}})],1),_c('FilterActions',{attrs:{"showClearFiltersButton":_vm.showClearFiltersButton,"clearFiltersCallback":_vm.resetFilters,"filterCallback":_vm.applyFilters,"changesDetected":_vm.changesDetected}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }