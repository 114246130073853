<template>
    <div id="content" class="groups">
        <template v-if="form">
            <Form :id="groupID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button bType="create" :bCallback="addGroup" bLabel="groups.add" :customClass="{ disabled: !hasAccess }"></Button>
            </div>

            <div class="groups-container supervise-container" v-if="items" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div class="sidebar-filters" :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                    <FilterGroups @noChanges="noChanges = true" @changes="noChanges = false" :isDisabled="noChanges && Object.values(items).length == 0" @resetPageDatatable="top(true)"></FilterGroups>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table v-if="items" v-model="selected" :headers="headers" fixed-header :items="Object.values(items)" sort-by :sort-asc="true" hide-default-footer :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                        <template #item.name="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.locations="item">
                            <div @click="goToReport(item.item.id)" v-if="item.item.locations">
                                <span>{{ Object.values(item.item.locations).length }}</span>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div @click="goToReport(item.item.id)">
                                <Table-statuses :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''" :status="item.item.status == '1' ? 'active' : 'inactive'"> </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination v-model="pagination.page" @input="top(false)" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable @buttonAction="addGroup" :buttonText="$t('groups.add')" :title="$t('empty_table.users_title')" :description="$t('empty_table.groups')"></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import i18n from '@/i18n'
import FilterGroups from '@/components/domain/groups/filter'
import Form from '@/components/domain/groups/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterGroups,
        Form,
        EmptyTable,
        TableStatuses
    },
    name: 'groups',
    data() {
        return {
            noChanges: false,
            groupID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('groups.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '200px',
                    class: 'header-table'
                },
                {
                    text: this.$t('groups.number_sites'),
                    value: 'locations',
                    align: 'start',
                    width: '200px',
                    sortable: true,
                    sort: this.sortColumnGroup,
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.state'),
                    value: 'status',
                    align: 'start',
                    width: '125px',
                    sortable: true,
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['groups/getList']()
        },
        itemsLoaded() {
            return this.$store.getters['groups/getItemsLoaded']
        },
        hasAccess() {
            return this.checkPermission('/groups/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            // console.log(routePath, route)
            if (this.$store.getters['hasAccess'](route)) {
                return true
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('groups.form.no_permission'),
                    duration: 5000
                })
            }
        },
        goToReport(id) {
            if (this.checkPermission('/groups/idGroup')) {
                this.form = true
                this.groupID = id
            }
        },
        addGroup() {
            if (this.checkPermission('/groups/add')) {
                this.form = true
                this.groupID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('groups.form.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.groupID = false
            this.$router.push({
                name: 'Groups',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        load() {
            var self = this
            this.$store.dispatch('groups/loadFilters', {}).then(function () {
                if (self.$route.params.id) {
                    self.goToReport(self.$route.params.id)
                } else if (self.$route.name == 'AddGroup') {
                    self.addGroup()
                } else {
                    self.form = false
                }
                var items = self.$store.getters['location/getListTable']()
                if (Object.keys(items).length > 0) {
                    // self.$store.dispatch("groups/loadList", {}).then(function () {});
                } else {
                }
            })
        },

        sortColumnGroup(a, b) {
            var aVal = Object.values(a)
            var bVal = Object.values(b)
            return bVal.length - aVal.length
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('locationsGroupsFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('locationsGroupsFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route: 'load'
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.groups {
    .groups-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 3px;
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
